import { useIsBelarus } from 'src/hooks/useCountries';
import { useSelector } from 'src/hooks/useSelector';

const Features = {
    enableAdvertisementAgreement: 'enable_advertisement_agreement',
};

const useShowAdvertisementAgreement = (): boolean => {
    const enableAdvertisementAgreement = useSelector(
        ({ features }) => features[Features.enableAdvertisementAgreement] as boolean
    );
    const isBelarus = useIsBelarus();
    return isBelarus && enableAdvertisementAgreement;
};

export { useShowAdvertisementAgreement };
