import { useIsRussia } from 'src/hooks/useCountries';
import { useSelector } from 'src/hooks/useSelector';

const Features = {
    enableAdvertisementSubscriptionApplicant: 'enable_advertisement_subscription_applicant_checkbox',
};

const useShowAdvertisementSubscriptionApplicant = (): boolean => {
    const enableAdvertisementAgreement = useSelector(
        ({ features }) => features[Features.enableAdvertisementSubscriptionApplicant] as boolean
    );
    const isRussia = useIsRussia();
    return isRussia && enableAdvertisementAgreement;
};

export { useShowAdvertisementSubscriptionApplicant };
