import { useState, useEffect } from 'react';

// Позволяет задать значения полей формы, которые были добавлены пользователем до инициализации js
const useInitialFormFields = (formElement, fields) => {
    const [initialFormData, setInitialFormData] = useState(Object.fromEntries(fields.map((field) => [field, ''])));

    useEffect(() => {
        if (!formElement.current) {
            return;
        }
        const formData = new FormData(formElement.current);
        setInitialFormData(
            fields.reduce((result, item) => {
                result[item] = formData.get ? formData.get(item) : '';
                return result;
            }, {})
        );
    }, [fields, formElement]);

    return initialFormData;
};

export { useInitialFormFields };
