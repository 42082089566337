import { FC, PropsWithChildren, ReactElement } from 'react';

import { Button as MagritteButton, ButtonSize, ButtonStyle } from '@hh.ru/magritte-ui';
import Button, { ButtonType, ButtonKind, ButtonProps } from 'bloko/blocks/button';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

import { useIsClient } from 'src/hooks/useIsClient';
import { useMagritte as useRedesign } from 'src/hooks/useMagritte';

interface Props {
    isFetching: boolean;
    size?: ButtonSize;
    style?: ButtonStyle;
}

const Index: FC<Props & Partial<ButtonProps> & PropsWithChildren> = ({
    isFetching,
    children,
    size = 'large',
    style = 'accent',
    ...props
}) => {
    const isClient = useIsClient();
    const isRedesign = useRedesign();

    if (isRedesign) {
        return (
            <MagritteButton
                {...props}
                mode="primary"
                Element={props.Element as 'button' | undefined}
                icon={props.icon as ReactElement}
                type="submit"
                disabled={!isClient || isFetching}
                style={style}
                loading={isFetching}
                size={size}
            >
                {children}
            </MagritteButton>
        );
    }

    return (
        <Button
            {...props}
            type={ButtonType.Submit}
            disabled={!isClient || isFetching}
            kind={ButtonKind.Primary}
            loading={isFetching && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
        >
            {children}
        </Button>
    );
};

export default Index;
